/* Dashboard responsive css  */

@media only screen and (min-width: 1201px) and (max-width: 1399px) {
  .menu-ul-li {
    width: 100%;
    min-width: 0px !important;
}
  .common-h2 {
    padding: 0.8rem;
    font-size: 16px;
  }

  .detail-dontent-div2,
  .detail-dontent-div {
    padding: 1rem;
  }

  .helpline-div,
  .booking-div {
    padding: 1rem;
  }

  .review-div {
    padding: 1rem;
    text-align: center;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .common-h2 {
    padding: 0.5rem;
    font-size: 16px;
  }

  .detail-dontent-div2,
  .detail-dontent-div {
    padding: 1rem;
  }

  .helpline-div,
  .booking-div {
    padding: 1rem;
  }

  .review-div {
    padding: 0.5rem;
    text-align: center;
  }
}

@media (max-width: 991px) {
  .dashboard-row {
    display: block;
  }

  .same-property {
    margin: 0.8rem 0;
  }

  .booking-div {
    padding: 0.5rem;
  }
  .earning-div {
    padding: 0 !important;
  }

  .data-h2 {
    padding-top: 1rem;
  }

  .detail-dontent-div2 {
    padding: 1rem 1.5rem;
  }
}

@media (max-width: 576px) {
  .heading-div {
    padding-right: 0;
  }
}

@media (max-width: 1600px) {
  .detail-dontent-div {
    flex-wrap: nowrap;
  }
}

/* Booking Responsive css  */

@media screen and (min-width: 1201px) and (max-width: 1400px) {
  .calender-set .today-div {
    width: 100%;
  }

  .list-calender {
    width: 100%;
  }

  .name {
    font-size: 12px;
  }

  .list-group-item {
    padding: 3px !important;
  }
}

@media screen and (min-width: 992px) and (max-width: 1200px) {
  .name {
    font-size: 10px;
  }

  .list-group-item {
    padding: 3px !important;
  }

  .ppic {
    width: 25px;
    height: 25px;
    padding: 0 !important;
  }

  .today-div {
    width: 85% !important;
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .name {
    font-size: 10px;
  }

  .list-group-item {
    padding: 3px !important;
  }

  .ppic {
    width: 25px;
    height: 25px;
    padding: 0 !important;
  }

  .today-div,
  .card {
    width: 85% !important;
  }
}

/* Client Responsive Css  */

@media (max-width: 576px) {
  .table > :not(caption) > * > * {
    padding: 0 !important;
  }
}

/* Earning Responsive Css  */

@media screen and (max-width: 1200px) {
  .common-h2-p {
    font-size: 20px;
  }
}
@media (min-width: 1200px) and (max-width: 1400px) {
  .common-h2-p {
    font-size: 16px;
  }
  .menu-div {
    width: calc(100% - 30px);
  }
  .menu-ul-li {
    min-width: 80px;
    min-height: 80px;
  }

  .menu-ul-li a img {
    width: 28px;
    height: 28px;
  }
  .heading {
    font-size: 11px;
  }
}

/* Medical history Responsive Css  */

@media screen and (min-width: 768px) and (max-width: 1400px) {
  .new_consultation_booking h6 {
    font-size: 14px !important;
  }
  .peronal-content-detail h6 {
    font-size: 14px !important;
  }
  .update_btn {
    font-size: 14px !important;
  }
  .profile_pic {
    width: 100px;
  }
}
@media (max-width: 767px) {
  .new_consultation_booking h6 {
    font-size: 14px;
  }
}

/* Prescription Info Responsive Css  */

@media screen and (min-width: 768px) and (max-width: 991px) {
  .logo-img {
    width: 300px;
  }

  .logo-div {
    padding: 23rem;
  }
}

@media screen and (max-width: 767px) {
  .logo-img {
    width: 180px;
  }

  .logo-div {
    padding: 1rem !important;
    text-align: left;
  }

  .signin-h2 {
    font-size: 20px;
    line-height: 30px;
  }

  .enter-h3 {
    font-size: 12px;
    line-height: 16px;
  }

  .label {
    font-size: 12px;
  }

  .input {
    padding: 0.4rem;
    font-size: 10px;
  }

  .signin-btn {
    width: 280px;
  }

  .eye_img_div {
    right: -1rem;
    top: 0.2rem;
  }
}

/* Forgot Pass Responsive Css  */

@media screen and (max-width: 991px) {
  .login_desc p,
  h4 {
    color: white !important;
  }

  .text_flex_end {
    align-items: center;
  }

  .sign_in {
    height: auto;
  }
}

@media screen and (max-width: 767px) {
  .sign_in {
    height: auto;
  }

  .login_desc p,
  h4 {
    color: black !important;
  }
}
@media screen and (max-width: 576px) {
  .sign_in,
  .card-body {
    padding: 1rem !important;
  }
}
