body {
  margin: 0;
  font-family: Poppins !important;
}
:root{
  --sea-green:rgba(27, 149, 188, 1);
  --light-grey:rgba(147, 149, 173, 1);
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
a{
  text-decoration: none !important;
}
li {
  list-style-type: none !important;
}