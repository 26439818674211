/* .header-container{
 background-color: white;
}
.header-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  padding: 0 3rem;
  font-family: Poppins;
  border-radius: 0px, 0px, 2px, 0px;
  border-bottom: 2px solid rgba(245, 245, 245, 1);
  height: 100px;
}

.header-logo-img {
  width: 201px;
  height: 100px;
}

.signout {
  font-size: 20px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: -0.02em;
  text-align: center;
  color: rgba(27, 159, 188, 1);
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
  width: 170px;
  height: 50px;
  border-radius: 10px 0 0 0;
  border: 1px solid white;
  background-color: white;
 cursor: pointer;
}
@media screen and (min-width:991px) and (max-width:1400px) {
  .signout{
    font-size: 18px;
    width: 130px;
  }
  .header-row{
    padding: 0 1rem;
  }
} */

.header_main {
  position: fixed;
  background: #fff;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 9;
  /* box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1) !important; */
  border-bottom: 2px solid rgba(245, 245, 245, 1);
}
header {
  position: absolute;
  width: 100%;
  z-index: 9;
}
.header_inner {
  padding: 14px 0px;
}
.header_inner img {
  width: 140px;
}

.header_inner .white-btn {
  margin: 0;
}

/* .main-logo-header {
  width: 80%;
} */

.header_main .white-btn {
  /* box-shadow: 0px 4px 14px rgba(201, 198, 198, 0.4) !important; */
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1) !important;
  border-radius: 5px !important;
  background: #fff !important;
  font-size: 10px !important;
  color: #1b95bc !important;
  width: 130px !important;
  border: 0 !important;
  line-height: 100% !important;
  display: inline-block !important;
  padding: 14px !important;
  text-align: center !important;
  font-weight: 600 !important;
  margin: 0 !important;
  cursor: pointer !important;
}
.navbar-toggler-icon {
  background-image: url(../../Assets//Icons/hamburgermenu.svg) !important;
  background-repeat: no-repeat !important;
}

/* Dashboard css   */

.dashboard-row {
  /* display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, auto);
  gap: 25px; */
}

.setting-div {
  grid-row: span 2;
}

.display-table {
  display: table;
}

.same-property {
  font-family: Poppins !important;
  border: 0.2px solid rgba(245, 245, 245, 1);
  border-radius: 0px, 1px, 0px, 0px;
  box-shadow: 0px 4px 14px 0px rgba(96, 98, 106, 0.1);
  border-radius: 10px;
  background-color: white;
  /* padding: 1rem 0; */
  font-size: 16px;
  font-weight: 700 !important;
  line-height: 32px !important;
  letter-spacing: -0.02em;
  border-radius: 10px;
  border: 0.25px solid #ccd2e347;
  background: #fff;
  box-shadow: 0px 4px 14px 0px rgba(96, 98, 106, 0.05);
}

.heading-div {
  border-bottom: 1px solid rgba(0, 0, 0, 1);
  padding: 0 0rem;
  padding-right: 10rem;
}

.common-h2 {
  margin: 0;
  padding: 1.5rem;
  font-size: 18px;
  font-weight: 700;
}

.img {
  width: 100px;
  height: 100px;
}

.detail-dontent-div {
  padding: 1rem 2rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: start;
  align-items: center;
  align-content: center;
  gap: 10px;
}

.common-h3-h4 {
  margin: 0;
  font-weight: 700;
  letter-spacing: -0.02em;
  font-family: DM Sans;
}

.accountname-h3 {
  font-size: 16px;
  line-height: 10px;
}

.dentist-h4 {
  font-size: 14px;
  color: rgba(147, 149, 173, 1) !important;
}

.detail-dontent-div2 {
  padding: 1rem 2rem;
  border-top: 0.75px solid rgba(233, 237, 247, 1);
}

.bookings-earnings-div {
  padding-top: 0.8rem;
  display: grid;
  grid-template-columns: repeat(2, auto);
  grid-template-rows: repeat(2, auto);
  gap: 5px;
}

.common-div {
  font-family: DM Sans;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: -0.02em;
}

.div2,
.div4 {
  color: rgba(27, 149, 188, 1);
}
.data-div {
  /* display: flex;
  justify-content: center; */
  align-items: center;
}

.data-h2 {
  font-size: 24px;
  font-weight: 700;
}
.helpline-div {
  padding: 2rem;
}

.review-div {
  padding: 1rem 2rem;
}

.submit-btn {
  font-size: 14px;
  padding: 0.7rem 1.2rem;
  text-align: center;
  color: white;
  background-color: rgba(27, 149, 188, 1);
  border-radius: 5px;
  border: 1px solid rgba(27, 149, 188, 1);
  cursor: pointer;
}

.booking-div {
  text-align: center;
  padding: 2rem;
}

.circle {
  display: inline-block;
  background: rgba(27, 149, 188, 0.6);
  border-radius: 50%;
  /* padding: 1rem 1.2rem; */
  width: 120px;
  height: 120px;
}

.h32-h3 {
  margin: 0;
  padding: 3rem 0;
  text-align: center;
  color: white;
  font-size: 26px;
}

span {
  color: rgba(27, 149, 188, 1);
}

.pending-h4 {
  color: rgba(27, 149, 188, 1) !important;
  margin: 0;
  margin-top: 12px;
  font-size: 16px;
  margin-bottom: 12px;
}

.potential-h2 {
  font-size: 16px;
  text-align: center;
  padding: 1rem;
}

.modal-title {
  color: var(--sea-green);
}

.modal-header,
.modal-body {
  border: none !important;
  /* border: 0.2px solid rgb(242, 245, 255) !important;
  box-shadow: 0px 4px 14px 0px rgba(96, 98, 106, 0.1) !important; */
}

.modal-footer {
  border: none !important;
}

.star_center {
  padding: 0 10rem !important;
}

/* media query for >1200 */

/* @media only screen and (min-width:1201px) and (max-width:1399px) {
  .common-h2 {
    padding: 0.8rem;
    font-size: 18px;
  }

  .detail-dontent-div2,
  .detail-dontent-div {
    padding: 1rem;
  }

  .helpline-div,
  .booking-div {
    padding: 1rem;
  }

  .review-div {
    padding: 1rem;
    text-align: center;
  }
}

@media only screen and (min-width:992px) and (max-width:1200px) {
  .common-h2 {
    padding: 0.5rem;
    font-size: 18px;
  }

  .detail-dontent-div2,
  .detail-dontent-div {
    padding: 1rem;
  }

  .helpline-div,
  .booking-div {
    padding: 1rem;
  }

  .review-div {
    padding: 0.5rem;
    text-align: center;
  }
}

@media (max-width:991px) {

  .dashboard-row {
    display: block;
  }

  .same-property {
    margin: 0.8rem 0;
  }

  .booking-div {
    padding: 0.5rem;
  }
.earning-div{
  padding: 0 !important;
}

  .data-h2 {
    padding-top: 1rem;
  }

  .detail-dontent-div2 {
    padding: 1rem 1.5rem;
  }
}

@media (max-width:576px) {
  .heading-div {
    padding-right: 0;
  }
}

@media (max-width:1600px) {
  .detail-dontent-div {
    flex-wrap: nowrap;
  }
} */

/* booking css  */

.booking-h2 {
  font-family: Poppins;
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: -0.02em;
  text-align: left;
}

.name-div {
  margin-top: 10px;
}

.arrow-div img {
  height: 16px;
  width: 16px;
}

.today-div {
  background-color: white;
  box-shadow: 0px 4px 14px 0px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  border: 0.2px solid rgba(204, 210, 227, 1);
}

.today-div h3 {
  font-family: DM Sans;
  font-size: 14px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.02em;
  text-align: center;
  margin: 0;
}

.ppic {
  width: 35px;
  height: 35px;
  padding: 0;
}

.ppic-lg {
  width: 120px;
  height: 120px;
  padding: 0;
}

.name {
  font-family: DM Sans;
  font-size: 14px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.02em;
  text-align: left;
}

.view-div {
  padding-top: 27rem;
  padding-bottom: 1rem;
}

.view-div-set a {
  font-family: DM Sans;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.02em;
  text-align: center;
  color: var(--sea-green);
  margin-bottom: 10px;
  display: block;
}

.cancel-btn {
  color: rgba(226, 18, 18, 1);
  font-size: 16px;
  font-weight: 700;
  font-family: DM Sans;
}

.call-p {
  color: rgba(147, 149, 173, 1) !important;
}

.bookings {
  /* font-family: DM Sans; */
  text-align: left;
  line-height: 24px;
  letter-spacing: -0.02em;
}

.same-properties-heading {
  font-size: 18px;
  font-weight: 700;
}

.name-h5 {
  margin: 0;
}

.urgent-p {
  color: var(--sea-green);
}

.p1 {
  color: var(--light-grey);
  font-size: 16px;
  font-weight: 500;
  margin: 0;
  margin-left: 0.8rem;
}

.orange {
  color: rgba(239, 151, 73, 1);
  font-weight: 700;
}

.green {
  color: rgba(90, 180, 144, 1);
  font-weight: 700;
}

.calender-set .today-div {
  width: 95%;
  align-items: center;
  padding: 5px 10px;

  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1) !important;
  border: 0;
}

.time-set-list {
  display: flex;
  gap: 10px;
  padding: 5px 10px;
  align-items: center;
}

.time-set-list > div {
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.time-set-list > div p {
  margin-bottom: 0;
  font-size: 12px;
}

.faq-contact-box input:focus{
  outline: none !important;
}
.ad-card-prop {
  border-radius: 10px;
  border: 0.25px solid #ccd2e347 !important;
  background: #fff;
  box-shadow: 0px 4px 14px 0px rgba(96, 98, 106, 0.05) !important;
}

.list-calender {
  justify-content: space-between;
  min-height: 55vh !important;
  width: 95%;
  border-radius: 10px !important;
  border: 0.25px solid #ccd2e347 !important;
  background: #fff;
  box-shadow: 0px 4px 14px 0px rgba(96, 98, 106, 0.05) !important;
}

.list-calender .list-group {
  height: 55vh;
  overflow: auto;
  scrollbar-width: none;
}

.calender-set .list-group-item {
  border: none;
  border-bottom: 1px solid rgb(204 210 227 / 24%);
  border-radius: 0 !important;
  background-color: transparent !important;
}

.launch-btn {
  font-size: 16px;
  font-weight: 600;
  color: white;
  background-color: var(--sea-green) !important;
  padding: 0.7rem 2rem;
}

.detials-div1 {
  border-bottom: 0.75px solid rgba(224, 229, 242, 1);
}

.details-h5 {
  margin: 0;
  margin-top: 1.8rem;
  font-family: DM Sans;
  font-size: 16px;
  font-weight: 700;
}

.booking-h6 {
  font-size: 14px;
  font-weight: 500;
}

.booking {
  min-height: 55vh !important;
}

.span2 {
  font-weight: 600;
  margin-left: 2rem;
}

/* @media screen and (min-width:1201px) and (max-width:1400px) {
  .calender-set .today-div {
    width: 100%;
  }

  .list-calender {
    width: 100%;
  }

  .name {
    font-size: 12px;
  }

  .list-group-item {
    padding: 3px !important;
  }
}

@media screen and (min-width:992px) and (max-width:1200px) {
  .name {
    font-size: 10px;
  }

  .list-group-item {

    padding: 3px !important;
  }

  .ppic {
    width: 25px;
    height: 25px;
    padding: 0 !important;
  }

  .today-div {
    width: 85% !important;
  }
}

@media screen and (min-width:768px) and (max-width:991px) {
  .name {
    font-size: 10px;
  }

  .list-group-item {

    padding: 3px !important;
  }

  .ppic {
    width: 25px;
    height: 25px;
    padding: 0 !important;
  }

  .today-div,
  .card {
    width: 85% !important;
  }
} */

/* client css  */

.common-properties-clients {
  font-family: Poppins;
  font-weight: 700;
  letter-spacing: -0.02em;
  text-align: left;
}
.client {
  min-height: 80vh !important;
}
.client-heading-div h2 {
  font-size: 16px;
  line-height: 32px;
  margin: 0;
}
.set-pro-table {
  display: flex;
  justify-content: center;
  width: auto;
  min-width: 80px;
}

.search-img {
  width: 15px;
  height: 15px;
}

.search {
  border: 1px solid rgba(244, 247, 254, 1) !important;
  border-radius: 49px !important;
  background-color: rgb(242, 246, 255) !important;
  font-size: 14px !important;
  font-weight: 400;
  padding-left: 2.5rem;
}
.search_padding {
  padding-right: 9rem !important;
}
th {
  font-family: DM Sans;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.02em;
  text-align: left;
  color: rgba(163, 174, 208, 1) !important;
}

td {
  border: 0 !important;
  font-family: DM Sans;
  font-size: 14px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.02em;
  text-align: left;
  /* padding: 1rem !important; */
}
.table-row {
  padding-top: 1rem !important;
}
.pagination-nav {
  padding-left: 18rem;
  color: var(--sea-green) !important;
}
td img {
  width: 40px;
}
.dropdown-menu {
  /* left: 10px !important; */
  border: 0.2px solid rgb(238, 242, 255) !important;
  box-shadow: 0px 4px 14px 0px rgba(96, 98, 106, 0.1) !important;
  inset: 0 -100px auto auto !important;
}

/* @media  (max-width:576px) {
 
.table>:not(caption)>*>* {
  padding: 0 !important;
}
} */

/* Earning css  */

.side-card-div {
  /* border: 0.25px solid rgba(204, 210, 227, 1); */
  box-shadow: 0px 4px 14px 0px rgba(96, 98, 106, 0.05);
  border-radius: 10px;
  height: 50%;
}

.side-card {
  border-bottom: 1px solid rgba(233, 237, 247, 1);
}

.side-card h2 {
  font-weight: 700;
}

.side-card p {
  font-weight: 300;
  margin: 0;
}

.common-h2-p {
  font-family: Poppins;
  font-size: 18px;
  line-height: 32px;
  letter-spacing: -0.02em;
  text-align: left;
  margin-bottom: 0;
}
.pagination-nav1 {
  margin-left: 11rem;
}
.withdraw-btn {
  border: 1px solid var(--sea-green);
  background-color: var(--sea-green);
  border-radius: 11px;
  color: white;
  font-weight: 600;
  padding: 14px 16px;
  height: 40px;
  font-size: 14px !important;
  display: flex;
  justify-content: center;
  align-items: center;
}
/* @media screen and (max-width:1200px){
  .common-h2-p{
    font-size: 20px;
  }
}
@media  (min-width:1200px) and (max-width:1400px) {
  .common-h2-p{
    font-size: 18px;
  }
} */

/* My Account css  */

/* .common_shadow {
  border: 0.25px solid #ccd2e3;
  border-radius: 10px;
  min-height: 80vh;
} */

.new_consultation_booking h2 {
  border-bottom: 1px solid #000;
  padding: 30px;
}

.new_consultation_booking h6 {
  font-size: 17px !important;
  font-weight: 600 !important;
  line-height: 20px;
  letter-spacing: -0.02em;
  color: rgba(0, 0, 0, 1);
}

.commom-prop {
  font-family: Poppins;
  letter-spacing: -0.02em;
  text-align: left;
}

/* .common_title {
  font-size: 16px;
  margin: 0;
  padding: 19px 31px 12px;
  font-size: 34px;
  font-weight: 700;
  line-height: 32px;
} */

.personal_history_accordian > .card {
  cursor: pointer;
  width: 100% !important;
}


.new_consultation_booking .accordion-item:first-of-type>.accordion-header .accordion-button {
  border-bottom: .25px solid #ccd2e3 !important;
}

.new_consultation_booking .accordion-item {
  border: .25px solid #ccd2e3 !important;
}

.card {
  border: 0.25px solid #ccd2e3;
  border-radius: 10px;
  /* box-shadow: 0 10px 14px rgba(96, 98, 106, .05) !important; */
  /* box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1) !important; */

  margin-bottom: 0;
  /* border: 0.2px solid rgba(204, 210, 227, 1) */
}

.card-body {
  padding: 0 0 46px;
}

.account_info {
  padding: 16px 20px;
  position: relative;
  text-decoration: none;
  color: black;
}

.account_info h6 {
  width: 30%;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
}

.account_info p {
  text-align: left;
  width: 50%;
}

.update-history {
  margin: 0 0 0 17px;
}

.small_para {
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  margin: 0;
}

.account_info:after {
  content: "";
  display: inline-block;
  width: 16px;
  height: 16px;
  background: url("../../Assets/Icons/arrow.svg") no-repeat center;
  position: absolute;
  right: 20px;
  top: 55%;
  transform: translateY(-50%);
}

/* Member css  */

.customer-details-div {
  border-bottom: 1px solid rgba(0, 0, 0, 1);
}

.all_consultations_col {
  width: 48% !important;
}

.account_info_ h2,
p {
  font-family: DM Sans;
  font-size: 14px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.02em;
  text-align: left;
  margin: 0;
}

.account_info_ input {
  background: rgba(255, 255, 255, 1);
  /* box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25); */
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1) !important;

  border: 1px solid transparent;
  border-radius: 10px;
  padding: 0.5rem 0.8rem;
  margin-bottom: 0.8rem !important;
  font-size: 12px !important;
  font-weight: 700;
}

.all_consultations p {
  margin-bottom: 0;
}

.all_consultations th {
  color: black !important;
}

.table_link_ {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 700;
  line-height: 15px;
  text-align: center;
  color: rgba(43, 54, 116, 1) !important;
}

.text-color {
  color: var(--sea-green) !important;
}

.table_body {
  padding: 1rem !important;
  display: table;
  width: 95%;
  margin: 15px;
}

.table_head {
  display: table;
  width: 100%;
}

.table_div {
  width: 100%;
}

.table-rows {
  border: 0.2px solid rgb(232, 236, 247);
  /* box-shadow: 0px 4px 14px 20px rgba(0, 0, 0, 0.05); */
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1) !important;

  border-radius: 5px;
  margin-top: 12px;
}

.row1 {
  background-color: rgba(27, 117, 188, 0.25) !important;
}

.row3 {
  background-color: rgba(245, 245, 245, 1) !important;
}

.row {
  font-family: Poppins;
}

/* medical history css */

.customer-name span {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
}



.accordian_head h6 {
  font-size: 14px !important;
}

.common_shadow {
  border: 0.25px solid rgb(237, 240, 252);
  border-radius: 10px;
}

.new_consultation_booking h2 {
  padding: 20px;
  font-size: 16px;
}

/* .common_title {
  color: #000;
  font-size: 16px;
  font-weight: 700;
  margin: 0;
  padding: 19px 31px 12px;
  text-align: left;
} */

.arrow_down {
  width: 20px;
  height: 20px;
}

.medical_history {
  font-family: Poppins;
}

.faq-contact-box {
  padding: 20px;
  box-sizing: border-box;
}

.faq-contact-box > div {
  margin-bottom: 20px;
}

.faq-contact-box label {
  display: block;
  margin-bottom: 10px;
  font-weight: 500;
}

.launch-btn {
  font-size: 16px;
  font-weight: 600;
  color: white;
  background-color: var(--sea-green) !important;
  padding: 0.7rem 2rem;
  border: 0 !important;
  border-radius: var(--bs-border-radius) !important;
}

.faq-contact-main{
  border: 0.25px solid rgb(237, 240, 252);
  border-radius: 10px;
}

.faq-contact-box textarea {
  resize: none;
  border: 1px solid rgba(244, 247, 254, 1) !important;
  border-radius: 9px !important;
  background-color: rgb(242, 246, 255) !important;
  font-size: 14px !important;
  font-weight: 400;
  padding-left: 2.5rem;
  width: 100%;
}


.faq-contact-box input {
  border: 1px solid rgba(244, 247, 254, 1) !important;
  border-radius: 9px !important;
  background-color: rgb(242, 246, 255) !important;
  font-size: 14px !important;
  font-weight: 400;
  padding-left: 2.5rem;
  width: 100%;
  height: 50px;
}

.inner_div,
.medication_accordion {
  border: 0.2px solid rgb(237, 240, 252) !important;
  border-radius: 10px;
  box-shadow: 0px 2px 14px 0px rgba(0, 0, 0, 0.1);
}

/* .card {
  border: 0 !important;
} */

.btn_yes_no {
  width: 70px !important;
  padding: 10px !important;
  /* box-shadow: 0px 2px 4px 1px rgb(252, 234, 238) !important; */
  /* box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1) !important; */
  color: rgba(27, 117, 188, 1) !important;
}

.h6,
h5 {
  font-size: 18px !important;
  font-weight: 600 !important;
  line-height: 20px;
  letter-spacing: -0.02em;
  color: rgba(0, 0, 0, 1);
}

.btn_personal_info {
  width: 240px !important;
}

.new-pres {
  background-color: var(--sea-green);
  border-radius: 10px;
  color: white;
  font-size: 14px !important;
  font-weight: 700 !important;
  /* box-shadow: 0px 4px 14px 0px rgba(0, 0, 0, 0.05); */
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1) !important;
  border: 1px solid var(--sea-green);
  padding: 0.2rem 1.4rem;
  width: 130px;
}
.btn-div {
  padding: 0;
  display: flex;
  justify-content: space-between;
  gap: 15px;
}
.td_width_item {
  width: 40%;
}
.td_width_quantity {
  width: 20%;
}
.td_width_issuedate {
  width: 20%;
}
.table_scroll {
  max-height: 500px !important;
  overflow-y: auto !important;
}
.overflow-auto {
  scrollbar-width: none;
}
.scrollbar-hide {
  scrollbar-width: none !important;
}
/* .display-table{
    display: table;
  }
  .table-cell{
  display: table-cell;
  } */

.customer-details-h2 {
  font-size: 20px;
  line-height: 32px;
  font-family: Poppins;
  font-weight: 700;
  letter-spacing: -0.02em;
}

.customer-name span {
  cursor: pointer !important;
}
/* @media screen and (min-width:768px) and (max-width:1400px) {
  .profile_pic {
    width: 100px;
  }

  .h6 {
    font-size: 8px !important;
  }
}
@media (max-width:767px) {
  .row{
    display: flex;
    flex-direction: column !important;
  }
} */

/* Prescription info css  */

.accordion .accordion-item {
  /* border: 0 !important; */
  /* border: none !important; */

  border-radius: 10px !important;
  margin: 10px 0 !important;
  /* box-shadow: 0px 2px 14px 0px rgba(85, 83, 83, 0.1) !important; */
  /* box-shadow: 0px 4px 14px rgba(96, 98, 106, 0.05) !important; */
}

.accordion-button {
  border: 0.2px solid rgb(244, 247, 255) !important;
  border-radius: 10px !important;
  /* box-shadow: 0px 2px 14px 0px rgba(100, 99, 99, 0.1) !important; */
  box-shadow: 0px 4px 14px rgba(96, 98, 106, 0.05) !important;
}

.accordion-button {
  font-size: 16px !important;
  font-weight: 600 !important;
  line-height: 20px;
  letter-spacing: -0.02em;
  color: rgba(0, 0, 0, 1);
}

.accordion-button.collapsed::after {
  background-image: url(../../Assets/Icons/right_arrow.svg) !important;
  transition: transform 0.3s ease-in-out !important;
  transform: rotate(0deg);
}
.accordion-button::after {
  background-image: url(../../Assets/Icons/arrow_down.svg) !important;
  transition: transform 0.3s ease-in-out !important;
  transform: rotate(0deg) !important;
}

.update_btn {
  /* border-radius: 10px !important; */
  background-color: var(--sea-green) !important;
  width: 135px;
  font-weight: 500 !important;
}

/* Sign In Css  */

.signin-container {
  max-width: 1320px;
  width: 100%;
  margin: 0 auto;
  height: 100vh;
  font-family: DM Sans;
}

.signin-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.logo-div {
  background-color: rgba(27, 149, 188, 1);
  text-align: center;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.content-div {
  text-align: center;
}

.content-inner-div {
  display: inline-block;
  text-align: left !important;
  width: calc(100% - 220px);
}

.signin-h2 {
  font-size: 36px;
  font-weight: 700;
  line-height: 56px;
  /* letter-spacing: -0.02em; */
  color: rgba(43, 54, 116, 1);
  margin: 0;
  font-family: DM Sans, sans-serif;
}

.enter-h3 {
  font-size: 16px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: -0.02em;
  color: rgba(163, 174, 208, 1);
  font-size: 0.8125rem;
  font-family: DM Sans, sans-serif;
}

.form-div {
  padding: 1rem 0;
  width: 100%;
}

.label-div {
  padding: 0.7rem 0;
}

.label {
  font-size: 14px;
  font-weight: 500;
  line-height: 14px;
  letter-spacing: -0.02em;
  color: rgba(43, 54, 116, 1);
  font-family: DM Sans;
}

.checkbox-label-div .checkbox-div {
  display: flex;
  align-items: baseline;
  margin-right: 5px;
}

.-signin-container {
  background-color: #f4f7fe;
}

.input {
  border: 1px solid rgba(224, 229, 242, 1);
  padding: 1rem;
  border-radius: 12px !important;
}

.checkbox-forget-div {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  align-content: center;
}

.checkbox-label-div {
  display: flex;
  flex-wrap: wrap;
  justify-content: start;
  align-items: center;
  align-content: center;
  gap: 5px;
}

.loggedin-label {
  font-weight: 400;
  color: rgba(27, 149, 188, 1);
}

.forget-a {
  text-decoration: none;
  color: rgba(67, 24, 255, 1);
  cursor: pointer;
}

.checkbox {
  height: 16px;
  width: 16px;
}

.signin-btn {
  margin-top: 0.8rem;
  background-color: rgba(27, 149, 188, 1);
  border: 1px solid rgba(27, 149, 188, 1);
  color: white;
  padding: 1rem 6rem;
  border-radius: 16px;
  width: 100%;
  font-size: 0.8125rem;
  font-weight: 400;
  font-family: Poppins, sans-serif;
}

.signin-btn:hover {
  background-color: white;
  color: var(--sea-green);
}

.signinbtn-div {
  font-weight: 700;
  cursor: pointer;
}

.logo-img {
  width: 400px;
}

.eye_img_div {
  right: -5rem;
  top: 1rem;
}

/* @media screen and (min-width:768px) and (max-width:991px) {
  .logo-img {
    width: 300px;
  }

  .logo-div {
    padding: 23rem;
  }
}

@media screen and (max-width:767px) {
  .logo-img {
    width: 180px;
  }

  .logo-div {
    padding: 1rem !important;
    text-align: left;
  }

  .row {
    flex-direction: column-reverse;
  }

  .signin-h2 {
    font-size: 20px;
    line-height: 30px;
  }

  .enter-h3 {
    font-size: 12px;
    line-height: 16px;
  }

  .label {
    font-size: 12px;
  }

  .input {
    padding: 0.4rem;
    font-size: 10px;
  }

  .signin-btn {

    width: 280px;
  }

  .eye_img_div {
    right: -1rem;
    top: 0.2rem;
  }
} */

/* forgot pass css  */

.wrapper_login {
  background-image: url(../../Assets//Images/login-bg.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 100vh;
  align-items: center;
  display: flex;
  overflow-y: scroll;
  overflow-x: hidden;
}

.navbar_set {
  position: absolute;
  top: 20px;
  left: 40px;
  z-index: 99;
}



.faq-w-contact h2 {
  padding: 0px 30px;
  border: 0 !important;
}

.faq-w-contact .accordion-button:not(.collapsed) {
  color: #000  !important ;
  background-color: #fff !important;
  box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0 var(--bs-accordion-border-color);
}

.login_page .navbar_set img {
  width: 100%;
}

.navbar_set img {
  max-width: 160px;
}

.login_desc h4 {
  color: #1a1a1a;
  font-size: 16px;
  line-height: 17px;
  color: #000;
  font-weight: 700;
}

.login_desc p {
  font-size: 16px;
  line-height: 24px;
  color: #1a1a1a;
  font-weight: 500;
}

.sign_in {
  padding-bottom: 50px;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sign_in {
  max-width: 470px;
}

.w-100 {
  width: 100% !important;
}

.sign_in .card {
  border-radius: 10px;
}

.card {
  margin-bottom: 0;
  border: 0.25px solid #ccd2e3;
  /* box-shadow: 0px 4px 14px rgba(96, 98, 106, 0.05); */
  border-radius: 10px;
}

.sign_in .card-body {
  padding: 80px 55px 30px;
  margin: 0;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
}

.title_signin h1 {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #1a1a1a;
}

.form-group label {
  color: #333333;
  letter-spacing: 0.3px;
  font-size: 11px;
  line-height: 12px;
  padding: 0 0 0 16px;
}

.form-control {
  background: #efefef !important;
  border: unset !important;
  padding: 14px 16px !important;
  border-radius: 6px !important;
  font-size: 11px !important;
  line-height: 15px !important;
  color: #808080 !important;
}

.btn-custom {
  border-radius: 50px !important;
  padding: 10px 0px !important;
  font-family: "Poppins", sans-serif !important;
  font-style: normal;
  font-weight: 700 !important;
  font-size: 15px !important;
  line-height: 27px !important;
  letter-spacing: -0.02em !important;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25) !important;
  background: #1b95bc !important;
  border: 1px solid transparent !important;
}

.text_flex_end {
  justify-content: flex-end;
  display: flex;
  align-items: flex-end;
}

/* @media screen and (max-width:991px) {

  .login_desc p,
  h4 {
    color: white !important;
  }

  .text_flex_end {
    align-items: center;
  }

  .sign_in {
    height: auto;
  }
}

@media screen and (max-width:767px) {
  .sign_in {
    height: auto;
  }

  .login_desc p,
  h4 {
    color: black !important;
  }
}
@media screen and (max-width:576px) {
.sign_in, .card-body{
  padding: 1rem !important;
}
} */

/* Add Prescription css  */

.search-outer {
  border-radius: 50px;
}

.customer-name {
  font-family: DM Sans;
  font-size: 22px;
  font-weight: 700;
  line-height: 42px;
  letter-spacing: -0.02em;
  text-align: left;
  color: black !important;
}

.create-form,
.col-4 {
  border: 0.25px solid rgb(235, 241, 255);
  box-shadow: 0px 4px 14px 0px rgba(96, 98, 106, 0.05);
  border-radius: 10px;
}

.create-title {
  border-bottom: 1px solid rgba(0, 0, 0, 1);
}
/* 
.create-title h6 {
  font-size: 14px;
} */
/* .common_title {
  font-weight: 700;
  text-align: left;
  margin: 0;
  padding: 16px 25px 12px;
  color: #000;
  font-size: 16px;
} */

.common_title {
  font-weight: 700;
  text-align: left;
  margin: 0;
  padding: 19px 31px 12px;
  color: #000;
  font-size: 16px;
}

.new_consultation .common_title {
  padding: 0;
}

.overflow_scroll {
  overflow-y: scroll;
}

.chat {
  max-height: 200px;
  padding: 20px 0 10px 0;
  height: 60vh;
  overflow-x: hidden !important;
  scrollbar-width: thin;
}

.right-side-chat {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 40px;
}

.chat-area-height {
  height: 60vh;
  overflow-x: hidden !important;
  overflow: auto;
}

.right-side-chat span {
  width: auto;
  word-break: break-all;
  background: #1b95bc;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 10px 0px 0px 10px;
  padding: 8px 10px;
  color: #fff;
  font-size: 10px;
}

.right-side-chat.admin-text-right {
  margin: 0 0 12px auto;
  max-width: 270px;
}

.small_parah {
  font-weight: 500;
  font-size: 11px;
  line-height: 16px;
  color: #000;
  margin: 0;
}

.color_grey {
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #697386;
}

.left-side-chat {
  width: 100%;
  margin-bottom: 40px;
}

.left-side-chat span {
  width: auto;
  word-break: break-all;
  background: #fff;
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.1);
  border-radius: 0px 10px 10px 0px;
  padding: 8px 10px;
  margin-bottom: 40px;
  color: #000;
  font-size: 10px;
}
.left-side-chat.admin-text-left {
  margin: 0 auto 12px 0;
  display: flex;
  max-width: 270px;
}

.admin-text-left.left-side-chat span {
  margin-bottom: 0px;
}
.chat_send {
  padding: 20px 32px;
  border-top: 1px solid #000;
}

.live_chat_title h3 {
  border-bottom: 1px solid #000;
}

.chat_send input[type="text"] {
  border: 0;
  font-size: 12px;
}

.chat_send input[type="submit"] {
  background: #1b9fbc;
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  border: 0;
  max-width: 130px;
  padding: 5px 10px;
  color: #fff;
  text-align: center;
  width: 100%;
  font-size: 12px;
  height: 35px;
}
.chat_text {
  width: 100%;
  border: none;
  padding-top: 10px;
  resize: none;
  outline: none;
  font-size: 11px;
  background: transparent !important;
}
.h-337 {
  height: 337px;
}
.main-content .call_section .padding0 {
  padding: 0 !important;
}

.chat_text .form-control:focus{
  box-shadow: unset !important;
}

.live_chat .overflow_scroll {
  scrollbar-width: none;
}

.view-label-input {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 12px !important;
  line-height: 20px;
  letter-spacing: -0.02em;
  color: #000000 !important;
  padding-left: 0px !important;
}

.select_input {
  background: white !important;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25) !important;
  border-radius: 10px !important;
  padding: 10px !important;
}
.form-control:focus {
  /* background-color: #efefef; */
}
.form-control {
  padding: 14px 16px;
  border-radius: 6px;
  font-size: 11px;
  line-height: 15px;
  color: #808080;
  border: 1px solid #fbe9e9;
  background: transparent;
}
.create_new_btn {
  display: flex;
  justify-content: flex-end;
}

.create_new_btn .cre_new {
  border-radius: 7px;
  width: 125px;
}

.cre_new {
  background: #1b95bc !important;
  color: #fff !important;
  margin-left: 20px;
  border-color: transparent;
  border-radius: 10px;
  padding: 8px 16px;
  width: auto;
  font-weight: 600;
  font-size: 12px;
  line-height: 22px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  text-align: center;
}

.form-control:disabled {
  background-color: transparent !important;
}
.plus-btn {
  background-color: rgba(27, 159, 188, 1);
  border: 1px solid transparent !important;
  border-radius: 50% !important;
  display: flex;
  align-items: center;
  width: 40px;
  height: 40px;
  justify-content: center;
}
.cursor-pointer {
  cursor: pointer !important;
}
textarea.form-control:focus{
  box-shadow: unset !important;
}

/* textarea.form-control {
  min-height: 100%;
  background: transparent;
  padding: 0;
  resize: none;
} */

/* .input_ {
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25) !important;
  border: 1px solid transparent !important;
  resize: none !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  color: rgb(0, 0, 0) !important;
}


.create-btn {
  background-color: rgba(27, 149, 188, 1) !important;
  border: 1px solid transparent !important;
  border-radius: 10px !important;
  width: 135px;
  height: 50px;
}
.pres-input{
  width: 80%;
}
.pres-slip{
  background-color: rgba(221, 239, 225, 1);
  border: 0.25px solid rgb(244, 247, 255);
  box-shadow: 0px 4px 14px 0px rgba(96, 98, 106, 0.05);
border-radius: 10px;
} */

/* Account setting  Css */

.contact_preferneces .card {
  border: 0;
}
.medical_history_details .accordian_collapse .card-header h5 {
  border-bottom: 0;
  border-radius: 10px;
}

 .personal_inner_sec.dropdown_arrow {
  border-bottom: .25px solid #ccd2e3;
}

.medical_history_details .personal_inner_sec.dropdown_arrow.collapsed {
  border: 0;
}

.medical_history_details .save-fix-bottom {
  padding: 16px 10px;
  margin: 0;
}

.medical_history_details .small-save {
  margin: 0;
}
.inner_padding {
  padding: 25px 20px 30px;
}
.options_part .select_option_btn:hover {
  /* padding: 0.5rem ; */
  background: #1b9fbc;
  color: #fff !important;
}
.questions_options {
  width: 100%;
}
.desc_area {
  /* padding: 30px 20px; */
  /* height: 100%; */
  position: relative;
}
.select_option_btn {
  border-radius: 5px;
  background: #fff;
  font-size: 10px;
  color: #1b95bb;
  border: 0;
  line-height: 100% !important;
  display: inline-block;
  padding: 0.5rem 1rem !important;
  text-align: center;
  font-weight: 600 !important;
  max-width: 140px;
  width: 100%;
  transition: 0.3s;
}
.selected_color {
  background-color: #1b9fbc !important;
  color: #fff !important;
}
.box_shadow {
  box-shadow: 0px 2px 14px rgb(0 0 0 / 10%);
}

.content-inner-div .form-div input {
  outline: none !important;
  font-size: 0.8125rem;
}

.content-inner-div .form-div p {
  margin-bottom: 0;
}

.content-inner-div .form-div input::placeholder {
  font-size: 0.8125rem;
  color: #ced4da;
}

.menu-ul-li a img {
  width: 30px;
  height: 30px;
}

.review-div {
  display: flex;
  justify-content: center;
  padding-bottom: 30px;
}

@media (max-width: 991px) {
  .header_inner {
    padding: 19px 8px;
    justify-content: inherit !important;
  }
}
@media (max-width: 576px) {
  .header_inner img {
    width: 120px;
  }
  .header_main .white-btn {
    padding: 10px !important;
  }
}


.active-listing .time-set-list {
  background-color: var(--sea-green) !important;
  color: #fff !important;
}

/* Sidebar css  */

/* .sidebar_ {
  border-right: 1px solid rgba(245, 245, 245, 1);
  border-radius: 0px, 1px, 0px, 0px;
  height: 100%;
  background-color: white;
  text-align: left;
  width: 90%;
  border-bottom: 2px solid rgba(245, 245, 245, 1);
} */

.sidebar-row {
  padding: 2rem;
  text-align: center;
}
.side{
  border-right: 1px solid rgba(245, 245, 245, 1);
  border-radius: 0px, 1px, 0px, 0px;
}
.common {
  letter-spacing: -0.02em;
  font-weight: 600;

}

.name-h1 {
  font-size: 20px;
  line-height: 20px;
  margin: 0;
}

.member-h2 {
  font-size: 12px;
  line-height: 12px;
  color: rgba(176, 185, 197, 1);
  margin: 0;
  margin-top: 8px;
  font-family: "Poppins", sans-serif;
}

.menu-div {
  /* padding: 1rem; */
  padding-top: 2rem;
  width: calc(100% - 50px);
}

.img-upload-input {
  background: #ede9e9;
  position: absolute;
  width: 100px;
  height: 100px;
  /* left: 50%; */
  transform: translate(-50%);
  opacity: 0;
  cursor: pointer;
  top: 0px;
  border-radius: 50px;
}

/* .pic-name-div {
  position: relative;
} */

.show-img-profile {
  width: 100px;
  height: 100px;
  object-fit: cover;
  box-shadow: 0px 4px 14px 0px rgba(0, 0, 0, 0.25);

  /* background-color: #efefef; */
  /* display: flex;
  justify-content: center;
  align-items: center; */
  border-radius: 50%;
}

/* .menu-ul {
 
  display: flex;
  flex-wrap: wrap;
 padding: 0;
  gap: 30px;
 

} */

.menu-ul {
  flex-wrap: wrap;
  padding: 0;
  gap: 30px;
  display: grid;
  grid-template-columns: 1fr 1fr;
}
/* 
.menu-ul-li {
  box-shadow: 0px 4px 14px 0px rgba(0, 0, 0, 0.25);
padding: 10px;
  border-radius: 10px;
  width: 100%;

} */

.menu-ul-li {
  box-shadow: 0px 4px 14px 0px rgba(0, 0, 0, 0.25);
  padding: 10px;
  border-radius: 10px;
  width: 100%;
  min-width: calc(100% - 10px);
  min-height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sidebar_tab_active {
  background-color: rgba(27, 159, 188, 1);
  color: white !important;
}

a,
Link {
  text-decoration: none;
  text-align: center;
}

.heading {
  font-size: 12px;
  line-height: 12px;
  color: rgba(27, 159, 188, 1);
  padding-top: 1rem;
  margin: 0;
  font-weight: 600;
}
.wrap {
  text-align: center;
}


.video_call_frame {
  position: absolute;
  right: 1px;
  bottom: 1px;
  width: 229px;
  height: 106px !important;
}

.start_call {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.full-video {
  aspect-ratio: unset;
  object-fit: cover;
  width: 100%;
  padding: 0;
  box-sizing: border-box;
  border-radius: 10px;
  z-index: 1;
  position: relative;
  height: 303px;
}

.start_call .cre_new {
  margin: 0;
  border-radius: 10px;
  width: 150px;
  border: 0;
  font-size: 11px;
}


video.doctor-video {
  width: 229px;
  position: absolute;
  right: 2px;
  bottom: 1px;
  border-radius: 10px;
  z-index: 2;
  height: 106px;
  object-fit: cover;
}

.main-content .video_call_section {
  border: 0 !important;
  box-shadow: unset !important;
}

.card.video_call_section {
  border: 0 !important;
  box-shadow: unset !important;
  line-height: 0;
}

.consulatation_card {
  position: relative;
}

.join_call_notes .consulatation_card {
  height: 289px;
}

.video_call_section .consulatation_card .large_img {
  border-radius: 10px;
  width: 100% !important;
}

.cons-top-bar {
  padding-bottom: 0px;
}
.page-title-box {
  padding-bottom: 20px;
}

.page-title-box h4 {
  text-transform: capitalize;
  font-weight: 600;
  color: #000;
  font-family: "DM Sans", sans-serif;
  margin-top: 10px;
}

.page-title-box h4 span {
  margin-left: 20px;
  font-size: 20px;
}

.page-title-box h4 {
  text-transform: capitalize;
  font-weight: 600;
  color: #000;
  font-family: "DM Sans", sans-serif;
}

.small_text {
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 24px;
  color: #707eae;
  font-family: "DM Sans", sans-serif;
}

span.name_color {
  color: #1b95bc;
  line-height: 34px;
  margin: 0 !important;
}

.visibility-hidden {
  visibility: hidden;
}

.main-content .video_call_section {
  border: 0 !important;
  box-shadow: unset !important;
}

.card.video_call_section {
  border: 0 !important;
  box-shadow: unset !important;
  line-height: 0;
}

.video_call_section .consulatation_card .large_img {
  border-radius: 10px;
  width: 100% !important;
}



.live_chat_table_fix_height {
  height: 300px;
  /* display: table-caption; */
  width: 100%;
  overflow: auto;
}

.main-content .card {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 0 solid #f6f6f6;
  border-radius: 20px;
  margin-bottom: 24px !important;
  -webkit-box-shadow: 0 0.75rem 1.5rem rgba(18, 38, 63, 0.03) !important;
  box-shadow: 0 0.75rem 1.5rem rgba(18, 38, 63, 0.03) !important;
}


ul.pagination li {
  color: #fff;
}

.pagination li a {
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1px;
  border-radius: 5px;
  border: 1px solid #ddd;
}

.pagination {
  display: flex;
  list-style: none;
  /* max-width: 470px; */
  justify-content: center;
  gap: 10px;
  margin: auto;
  align-items: center;
}

.pagination_inner {
  margin: 0 auto;
  width: 100%;
  display: block !important;
  margin-bottom: 20px;
}

li.previous.disabled,
li.next.disabled {
  background: transparent;
  box-shadow: none;
  padding: 5px 10px;
}

li.selected a {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px;
  background: #1b95bc;
  color: #fff !important;
  font-weight: 600;
  border-radius: 4px;
}

ul.pagination li {
  color: #1b95bc;
}

li.next a,
li.previous a {
  color: #1b94ba;
  font-size: 20px;
  font-weight: 400;
}

li.previous.disabled a,
li.next.disabled a {
  color: #a2a9bf !important;
  font-size: 20px;
  font-weight: 400;
}

li.previous {
  padding: 0px 10px !important;
}

li.previous {
  padding: 5px 10px !important;
}

.next {
  background: transparent;
  box-shadow: none;
  padding: 5px 10px !important;
}

.dealer-table-c tbody {
  /* display: block; */
  overflow-y: auto;
  table-layout: fixed;
  max-height: 400px;
  height: 400px;
}


.dealer-table-c tr {
  display: table;
  width: 100%;
  table-layout: fixed;
}
.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}
.dealer-table-c td {
  width: 150px;
}

.main-content-client {
  padding: 10px;
  margin-bottom: 10px;
}


.table-responsive {
  position: relative; /* Ensure the pseudo-elements are positioned relative to the container */
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  border-radius: 10px; /* Rounded corners */
}

.table-responsive::-webkit-scrollbar {
  height: 8px;
  background-color: #f5f5f5;
  border-radius: 10px;
}

.table-responsive::-webkit-scrollbar-thumb {
  background-color: var(--sea-green);
  border-radius: 10px;
}

.table-responsive::-webkit-scrollbar-thumb:hover {
  background-color: var(--sea-green);
}

.table-responsive::-webkit-scrollbar-track {
  background-color: #eee;
  border-radius: 10px;
}

.new-profile-image {
  width: 100px;
  max-width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
  box-shadow: 0px 4px 14px 0px rgba(0, 0, 0, 0.25);
}

.ad-w-100 {
  min-width: 101px;
}


.list-image {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  object-fit: cover;
}

.pres_width {
  width: 450px;
  position: absolute;
  top: 20px;
  right: 16px;
}

.hola-amigo {
  display: flex;
  gap: 10px;
  justify-content: center;
  width: 180px;
  margin-bottom: 10px;
}

.overflow-height {
  height: 40vh !important;
  overflow: scroll !important;
}


.white-btn {
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.4) !important;
  border-radius: 5px !important;
  background: #fff !important;
  font-size: 10px !important;
  color: #1b95bc !important;
  width: 130px !important;
  border: 0 !important;
  line-height: 100% !important;
  display: inline-block !important;
  padding: 14px !important;
  text-align: center !important;
  font-weight: 600 !important;
  margin: 10px 0 0 0 !important;
  cursor: pointer !important;
}


@media screen and (min-width:992px) {
  /* .side{
    width: 80%;
  } */
}
@media only screen and (min-width:1401px) and (max-width:1600px) {
  .sidebar-row {
    padding: 1rem 0.5rem;
    padding-right: 1.5rem;
  }
/* 
  .menu-div {
    padding-top: 1rem;
  } */

  .menu-ul {
    gap: 15px;
    padding: 0;
    /* padding-left: 1rem; */
  }
  .menu-ul-li{
    width: 100%;
    padding-top: 1.5rem;
  }

}
@media only screen and (min-width:1201px) and (max-width:1400px) {
  .sidebar-row {
    padding: 1rem 0.5rem;
    padding-right: 1.5rem;
  }

  /* .menu-div {
    padding-top: 1rem;
  } */

  .menu-ul {
    gap: 15px;
    padding: 0;
    /* padding-left: 0.5rem; */
  }
  .menu-ul-li{
    width: 100%;
  }

}

@media only screen and (min-width:992px) and (max-width:1200px) {
  .menu-ul-li {
    width: 100%;

  }
.menu-div{
  padding: 0;
  padding-top: 2rem;
  width: calc(100% - 30px);
}
  .menu-ul {
  gap: 15px; 
  padding: 0;
  /* padding-left: 1rem; */
  }

  .heading {
    padding-top: 0.4rem;
    font-size: 10px;
  }

  .sidebar-row {
    padding: 1rem;
  }
}

@media only screen and (max-width:991px) {
  .menu-ul-li {
  
    width: 100%;
  }
.menu-ul{
  gap: 10px;
}
  .menu-ul-li div a {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    align-items: center;
  }

  .heading {
    padding-top: 0;
  }

  .icons {
    width: 20px;
  }
  .img-upload-input{
    top: 0px;
  }
}


.blue-text-box {
  border-radius: 5px;
  background: #fff;
  font-size: 11px;
  color: #1b95bb;
  border: 0;
  line-height: 100% !important;
  display: inline-block;
  padding: 12px 10px;
  text-align: left;
  font-weight: 600 !important;
  width: 100%;
}


.box_shadow {
  box-shadow: 0px 2px 14px rgb(0 0 0 / 10%);
}

.client-set-histary h6 {
  font-size: 14px !important;
  padding: 10px;
}

.client-set-histary ul{
  padding: 0;
}

.client-set-histary ul li {
  margin-left: 0;
  margin-bottom: 20px;
}

.confirmed-modal p {
  text-align: center;
  margin: 0;
  font-size: 30px;
  color: #1b95bc;
  font-weight: 300;
  letter-spacing: 2px;
  text-transform: uppercase;
}

.confirmed-modal {
  height: 170px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.confirmed-modal>div svg path {
  fill: #1b95bc;
}

.border-none {
  border: 0 !important;
}

.min-h-100 {
  min-height: 100% !important;
}


.faq-w-contact h2{
  padding: 0 !important;
}

.faq-w-contact .accordion-flush {
  padding: 0px 20px;
}

.data-not-found {
  display: flex;
}

.data-not-found {
/* position: relative; */
}

.data-not-found p {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
} 

.custom-select {
    background-color: rgb(242, 246, 255) !important;
    padding: 10px;
    border-radius: 10px;
    outline: none;
    width: 100%;
}



.image_xray_view {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
}

.image_xray_view  button{

    background: #1b95bc !important;
    color: #fff !important;
    margin-left: 20px;
    border-color: transparent;
    border-radius: 10px;
    padding: 8px 16px;
    width: auto;
    font-weight: 600;
    font-size: 12px;
    line-height: 22px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    text-align: center;
}


.chat_buttons  .notes_btn .white-btn {
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.4) !important;
  border-radius: 10px !important;
  background: #fff !important;
  font-size: 10px !important;
  color: #1b95bc !important;
  width: 130px !important;
  border: 0 !important;
  line-height: 100% !important;
  display: inline-block !important;
  padding: 14px !important;
  text-align: center !important;
  font-weight: 600 !important;
  cursor: pointer !important;
  height: 40px;
  margin-top: 0 !important;
}

.chat_buttons  .notes_btn .cre_new {
  background: #1b95bc !important;
  color: #fff !important;
  margin-left: 0 !important;
  border-color: transparent;
  border-radius: 10px;
  padding: 14px !important;
  width: 130px !important;
  width: auto;
  font-weight: 600;
  font-size: 12px;
  line-height: 22px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  text-align: center;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.chat_buttons  .notes_btn {
  align-items: center;
  gap: 10px;

}

.select-btn-yes {
  border-radius: 5px;
  background: white;
  font-size: 13px;
  color: #1b95bb !important;
  border: 0;
  line-height: 100% !important;
  display: inline-block;
  padding: 10px 20px;
  text-align: center;
  font-weight: 600 !important;
  box-shadow: 0px 2px 14px rgb(0 0 0 / 10%);
  margin: 0px 10px;
}

.header_inner .small_white_btn {
  color: #1B95BC !important;
  width: 93px;
  padding: 13px 6px;
  margin: 0 0 0 15px;
  font-size: 13px !important;
}


.ad-review-txt {
  display: flex;
  justify-content: start;
  /* margin-top: 20px; */
}
/* 

.ad-review-txt button {
  border: 0;
  background: transparent;
  color: var(--sea-green) !important;
  text-decoration: underline;
} */

.rating-sec textarea {
   
  padding: 10px 10px;
}
.rating-main-popup button.btn.btn-primary {
  padding: 12px 50px;
  background: #1b9fbc;
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  text-align: center;
  border: none;
}
.rating-sec h3 {
  font-size: 16px;
  margin-bottom: 13px;
  font-weight: 500;
  font-size: 19px;
  line-height: 25px;
  text-align: center;
  letter-spacing: -0.02em;
  margin: 0;
  color: #000;
}
.rating-sec h5 {
  font-size: 14px;
}

.rating-sec svg.star-svg {
  width: 35px;
}

.consultation-name-sv{
  background: #1b95bc !important;
  color: #fff !important;
  margin-left: 0 !important;
  border-color: transparent;
  border-radius: 10px !important;
  width: 130px !important;
  width: auto;
  font-weight: 600;
  font-size: 12px !important;
  line-height: 22px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  text-align: center;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}


.consultation-name-sv::after{
  margin-left: 1.255em !important;
}


.border-shadow {
  border: 0 !important;
  box-shadow: none !important;
}


.leave-call-txt {
  font-size: 20px;
}


.modal_center, .no_content {
  display: flex;
  justify-content: center;
}

.modal_center {
  margin-top: 20px;
}

.modal_center img {
  height: 550px;
  max-width: 100%;
  width: 700px;
  object-fit: cover;
}

.upcoming_con_title p {
  padding: 0 31px 6px;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  margin: 0;
}

.upcoming_con_title {
  border-bottom: 1px solid #000;
}

.common_title {
  font-weight: 700;
  text-align: left;
  margin: 0;
  padding: 19px 31px 12px;
  color: #000;
  font-size: 16px;
}

.consulatation_card {
  position: relative;
}

.notes_height .notes {
  height: 266px;
}

.textbox-readonly{
  height: auto
}

.questionnaire_view .collap-text {
  font-size: 12px;
}

.questionnaire_view .select-btn-yes {
  padding: 8px 13px;
  margin: 0px 5px;
  font-size: 10px;
}

.questionnaire_view {
  height: 554px;
  overflow: auto;
  scrollbar-width: none;
}

.questionnaire_view .subques {
  font-size: 11px;
  margin: 0 0 2px 0;
}

.collap-text {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  display: flex;
  align-items: center;
  color: #000000;
}

.questionnaire_view .collap-text {
  font-size: 12px;
}

.sub-question-show {
  margin-top: 20px;
  margin-bottom: 20px;
  /* border-bottom: 1px solid #e9e9e9; */
  padding-bottom: 20px;
}

.subques {
  color: #000;
  font-weight: 500;
  margin-bottom: 10px;
}

.box_shadow {
  box-shadow: 0px 2px 14px rgb(0 0 0 / 10%);
}

.history_input_field {
  border-radius: 5px;
  background: #fff;
  font-size: 12px;
  color: #1b95bb;
  border: 0;
  display: inline-block;
  padding: 13px 7px;
  font-weight: 600 !important;
  line-height: 20px !important;
  max-width: unset !important;
  text-align: left !important;
}

.ques_view_input_field {
  padding: 6px 7px;
  margin: 6px 0 0 0;
  min-height: 34px;
  max-height: 70px;
  overflow: auto;
}
.medHisColor {
  background-color: #1b9fbc;
  color: #fff !important;
}
.add-medication-btn button.btn.cre_new {
  margin-left: 0px;
}
 input.form-control:focus {
box-shadow: none  !important;
}
p.label-text {
  margin: 0px;
}

label.hint-text {
  color: #dc3545 !important;
  display: flex;
  margin-bottom: 2px;
}

label.hint-text span {
  color: #000;
}

.new-prescription textarea.form-control:focus {
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25) !important;
}

.form-select:focus{
  box-shadow:none !important;
}

.circle-bullet {
  color:#dc3545 !important;
  font-size: 20px; 
  margin: 0 0 0 4px; 
}


input.form-control:focus {
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25) !important;
}

.timer p {
  text-transform: capitalize;
  font-weight: 600;
  color: #707eae;
  font-family: "DM Sans", sans-serif;
  font-size: 20px;
  margin: 0px;
}

.timer span {
  font-weight: 600;
  font-size: 25px;
  color: #000;
  padding-top: 21px;
  display: block;
  font-family: "DM Sans", sans-serif;
}

.timer {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.doctor-send-btn {
  margin-top: 10px;
  text-align: right;
}
.doctor-send-btn button {
  width: 150px;
}
.availability-day {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin-bottom: 10px;
  gap: 14px;
}
.input-time-box {
  display: flex;
  gap: 10px;
  flex-direction: column;
  width: 100%;
}

.label-day label {
  width: 50px;
}

.input-time-box .time-dropdown {
  border: 1px solid #1B95BC;
  outline: none;
  font-size: 14px;
  font-weight: 600;
  color: #1B95BC;
  width: 100px;
  height: 32px;
  padding: 0px 6px;
  border-radius: 4px;
  background-color: #fff;
  cursor: pointer;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url('data:image/svg+xml;utf8,<svg fill="%231B95BC" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="M7 10l5 5 5-5z"/></svg>');
  background-repeat: no-repeat;
  background-position: right 8px center;
}

.input-time-box .time-dropdown:disabled {
  background-color: #f0f0f0;
  color: #999;
  cursor: not-allowed;
  border-color: #ccc;
}

.time-slot {
  display: flex;
  gap: 14px;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}
/* button.add-time-btn {
  border: none;
  box-shadow: none;
  background: transparent;
} */

button.add-time-btn {
  border: none;
  box-shadow: none;
  background: transparent;
  padding: 0; /* Remove any default padding */
  margin: 0; /* Remove any default margin */
  cursor: pointer;
}
.button-group {
  display: flex;
}


.setting-item {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 10px 0;
}

.setting-item h5 {
  font-size: 16px;
  font-weight: 500;
  color: #000000;
  margin: 0;
}

/* Toggle Switch Styles */
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 34px;
}

.slider.round {
  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: rgba(27, 149, 188, 1);
}

input:checked + .slider:before {
  transform: translateX(26px);
}

/* Optional: Add hover effects for better UX */
.slider:hover {
  opacity: 0.8;
}


.availability-checkbox:checked {
  background-color:#1B95BC !important;
  border-color: #1B95BC !important;
  background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"%3E%3Cpath d="M20 6L9 17l-5-5" /%3E%3C/svg%3E');
  background-size: 16px;
  background-position: center;
  background-repeat: no-repeat;
}


.availability-checkbox {
  -webkit-appearance: none; 
  -moz-appearance: none;    
  appearance: none;        
  width: 20px;            
  height: 20px;
  border: 2px solid #1B95BC; 
  border-radius: 4px;      
  cursor: pointer;
  position: relative;
  margin-left: 20px;
}


.availability-checkbox:checked::after {
  color: white;             
  font-size: 14px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.data-div h6 {
  font-size: 15px;
  font-weight: 500;
  color: #b0b9c5;
  margin: 0 0 10px;
}

.head-time{
  display: flex;
  justify-content: space-between;
}

.action-box {
  display: flex;
  align-items: center; /* Vertically center the items */
  /* justify-content: flex-end; Push content to the right */
  gap: 8px; /* Add spacing between elements */
}

 button.add-time-btn.minus-btton {
  position: relative;
}
button.add-time-btn.minus-btton svg {
  position: absolute;
  top: 5px;
} 

.token-input {
  width: 50% !important;
  /* margin: 0 10px; */
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  outline: none;
}

.availability-table {
    max-height: 430px;
    margin-top: 10px;
    overflow-x: scroll;
    overflow-y: scroll;
    scrollbar-width: none;
}



.admin-popup .modal-content {
  max-width: 500px;
  margin: 0 auto;
}

.admin-popup .modal-footer .confirmation_btn {
  padding: 11px 26px;
  font-size: 14px;
  line-height: 19px;
}

.admin-popup .form-control:focus {

  background-color: #efefef;
}

.provisional-popup .modal-header {
  border: 0;
}

.provisional-popup .modal-body h4 {
  text-align: center;
  font-size: 22px;
  color: #1b95bc;
  margin: 0 0 22px 0;
}

.provisional-popup .modal-footer {
  justify-content: center;
  border-top: 0;
  padding: 10px 0 35px;
}

.provisional-popup .modal-body {
  padding: 1rem 1.5rem;
}

.provisional-popup .modal-footer .confirmation_btn {
  padding: 11px 26px !important;
  font-size: 14px !important;
  line-height: 19px !important;
  border-radius: 0 !important;
  text-transform: uppercase;
}

.provisional-popup .modal-content {
  border-radius: 8px;
}

.detailed_popup .modal-footer .confirmation_btn {
  padding: 11px 26px !important;
  font-size: 14px;
  line-height: 19px;
  border-radius: 0 !important;
  text-transform: uppercase;
  width: 100% !important;
  max-width: unset;
}


.btn-custom {
  border-radius: 50px;
  padding: 10px 0px !important;
  border: none;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 27px;
  letter-spacing: -0.02em;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  background: #1b95bc;
  border: 1px solid transparent;
}
button.btn.btn-primary.cancel-btn {
    text-align: center;
    background: transparent;
    color: #1b95bc;
    font-weight: 600;
    border: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 0;
    font-size: 14px;
    text-transform: uppercase;
}

.btn-custom:hover {
  background: transparent !important;
  box-shadow: 14px 17px 40px rgba(112, 144, 176, 0.08) !important;
  border-radius: 50px !important;
  padding: 10px 0px !important;
  border: none;
  opacity: 0.9 !important;
  border: 1px solid #1b95bc !important;
  color: #1b95bc !important;
}
.cancel-btn:hover {
  background: transparent !important;
  text-decoration: underline !important;
  color: #1b95bc !important;
}

.modal-header {
  display: flex !important; 
  flex-shrink: 0 !important;
  align-items: center !important;
  justify-content: space-between !important;
  padding: 1rem 1rem !important;
  border-top-left-radius: calc(.4rem - 1px) !important;
  border-top-right-radius: calc(.4rem - 1px) !important;
}


.spinner {
  display: inline-block;
  width: 20px;
  height: 20px;
  border: 3px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top-color: #fff;
  animation: spin 1s ease-in-out infinite;
  margin-right: 8px;
  position: absolute;
  left: 43%;
  top: 20%;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

button.btn.cre_new {
  position: relative;
}


/* .textarea-box {
  height: 60px;
} */
.textarea-sec {
  display: flex;
  align-items: center;
}

/* .textarea.notes_input {
  padding: 25px 20px;
  height: 200px;
  position: relative;
} */

.cons_notes_height {
  height: 172px !important;
}

.consultation-notes-text-area {
  height: 250px;
}

.h-0 {
  height: auto !important ;
  margin-bottom: 30px;
}

/* .height-ad-chat-show{
  height: 160px !important;
} */