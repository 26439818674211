body {
  font-family: "Poppins", sans-serif !important;
}

.loader-style {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: #ffffff47;
  backdrop-filter: blur(3px);
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  z-index: 9;
  flex-direction: column;
}