.common-properties-clients {
  font-family: Poppins;
  font-weight: 700;
  letter-spacing: -0.02em;
  text-align: left;

}
.client{
  min-height: 80vh !important;
}
.client-heading-div h2 {
  font-size: 24px;
  line-height: 32px;
}

.set-pro-table {
  display: flex;
  justify-content: center;
  width: auto;
  min-width: 80px;
}

.search-img {
  width: 15px;
  height: 15px;
}

.search {
  border: 1px solid rgba(244, 247, 254, 1) !important;
  border-radius: 49px !important;
  background-color: rgb(242, 246, 255) !important;
  font-size: 14px !important;
  font-weight: 400;
  padding-left: 2.5rem;
}
.search_padding{
  padding-right: 9rem !important;
}
th {
  font-family: DM Sans;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.02em;
  text-align: left;
  color: rgba(163, 174, 208, 1) !important;
}

td {
  border: 0 !important;
  font-family: DM Sans;
  font-size: 14px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.02em;
  text-align: left;
  /* padding: 1rem !important; */
}
.table-row{
  padding-top: 1rem !important;
}
.pagination-nav{
  padding-left: 18rem;
  color: var(--sea-green) !important;
}
td img{
  width: 40px;
}
.dropdown-menu{
  /* left: 10px !important; */
  border: 0.2px solid rgb(238, 242, 255) !important;
  box-shadow: 0px 4px 14px 0px rgba(96, 98, 106, 0.10) !important; 
  inset: 0 -100px auto auto !important;
}
@media  (max-width:576px) {
 
.table>:not(caption)>*>* {
  padding: 0 !important;
}
}