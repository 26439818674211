.layout-wrapper{
  max-width: 1920px;
  margin: 0 auto;
  background-color: rgb(255, 250, 250);
  overflow-x: hidden;
  /* height: 100%; */
}
.side-content-div{
  display: flex;
  flex-wrap: wrap;
  justify-content: start;
  /* align-items: center; */
  align-content: center;
  
  margin-top: 72px !important;
  
  /* height: 100%; */
}
.main-content-layout {
  padding: 30px !important;
}

.content{
  /* padding: 2rem 0; */
  width: 70%;
  height: 100vh;
  /* margin-left: 23rem; */
}
@media only screen and (min-width:1201px) and (max-width:1400px){
  .content{
    /* padding: 1rem; */
    /* margin-left: 16rem; */
  }
}
@media only screen and (min-width:992px) and (max-width:1200px){
  .content{
    width: 80%;
    /* margin-left: 17rem; */
    /* padding: 1rem; */
  }
}
@media screen and (max-width:991px) {
  .content{
    margin-left: 0;
    /* padding: 1rem; */
    width: 100%;
  }
  /* .side-content-div{
    margin-top: rem;
  } */
  aside{
    /* position: fixed; */
    margin-top: -0.8rem !important;
  }
 
}
@media (max-width:767px) {

  
}


