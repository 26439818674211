.contact_preferneces .card {
  border: 0;
}
.medical_history_details .accordian_collapse .card-header h5 {
  border-bottom: 0;
  border-radius: 10px;
}

.medical_history_details .personal_inner_sec.dropdown_arrow {
  border-bottom: 0.25px solid #ccd2e3;
}

.medical_history_details .personal_inner_sec.dropdown_arrow.collapsed {
  border: 0;
}

.medical_history_details .save-fix-bottom {
  padding: 16px 10px;
  margin: 0;
}

.medical_history_details .small-save {
  margin: 0;
}
.inner_padding {
  padding: 25px 20px 30px;
}
.options_part .select_option_btn:hover {
  /* padding: 0.5rem ; */
  background: #1b9fbc;
  color: #fff !important;
}
.questions_options {
  width: 100%;
}
.desc_area {
  /* padding: 30px 20px; */
  /* height: 100%; */
  position: relative;
}
.select_option_btn {
  border-radius: 5px;
  background: #fff;
  font-size: 14px;
  color: #1b95bb;
  border: 0;
  line-height: 100% !important;
  display: inline-block;
  padding: 0.5rem 1rem !important;
  text-align: center;
  font-weight: 600 !important;
  max-width: 140px;
  width: 100%;
  transition: 0.3s;
}
.selected_color {
  background-color: #1b9fbc !important;
  color: #fff !important;
}
.box_shadow {
  box-shadow: 0px 2px 14px rgb(0 0 0 / 10%);
}
