.accordian_head {
  border-radius: 10px;
}
.accordian_collapse .card-header h5 {
  border-bottom: 0.25px solid #ccd2e3;
  border-radius: 10px;
}
.accordian_collapse .card-header {
  padding: 0;
}
.contact_preferneces .card {
  border: 0;
}
.card {
  margin-bottom: 0;
  /* border: 0.25px solid #ccd2e3; */
  /* box-shadow: 0px 4px 14px rgba(96, 98, 106, 0.05); */
  border-radius: 10px;
}
.card-header{
  border-bottom: 0 !important;
  padding: 0 !important;
}
.personal_inner_sec {
  padding: 16px ;
  background: #fff;
  border-radius: 10px;
  width: 100%;
  border: 0;
}
.dropdown_arrow {
  position: relative;
  text-decoration: none !important;
}
.dropdown_arrow.collapsed::after {
  content: url("../../Assets//Icons//right_arrow.svg");
  position: absolute;
  right: 20px;
}

.dropdown_arrow::after {
  content: url("../../Assets/Icons/arrow_down.svg");
  right: 20px;
  position: absolute;
}
.options_part a:hover {
  color: #000 !important;
}
.options_part ul li {
  border-radius: 8px;
  margin: 0 0 0 16px;
}
.questions_options_inner .colorcc:hover {
  color: #1b9fbc !important;
  background: #fff;
  box-shadow: 0px 2px 14px rgb(0 0 0 / 10%);
}
.options_part.questions_options ul {
  margin: 0;
}
.select_option_btn {
  max-width: 200px !important;
}
