/* .header-container{
 background-color: white;
}
.header-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  padding: 0 3rem;
  font-family: Poppins;
  border-radius: 0px, 0px, 2px, 0px;
  border-bottom: 2px solid rgba(245, 245, 245, 1);
  height: 100px;
}

.header-logo-img {
  width: 201px;
  height: 100px;
}

.signout {
  font-size: 20px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: -0.02em;
  text-align: center;
  color: rgba(27, 159, 188, 1);
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
  width: 170px;
  height: 50px;
  border-radius: 10px 0 0 0;
  border: 1px solid white;
  background-color: white;
 cursor: pointer;
}
@media screen and (min-width:991px) and (max-width:1400px) {
  .signout{
    font-size: 18px;
    width: 130px;
  }
  .header-row{
    padding: 0 1rem;
  }
} */









.header_main {
  position: fixed;
  background: #fff;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 9;
  /* box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1) !important; */
  border-bottom: 2px solid rgba(245, 245, 245, 1)
}
header {
  position: absolute;
  width: 100%;
  z-index: 9;
}
.header_inner {
  padding: 14px 0px;
}
.header_inner img {
  width: 140px;
 
}

.header_inner .white-btn {
  margin: 0;
}

/* .main-logo-header {
  width: 80%;
} */


.header_main .white-btn {
  /* box-shadow: 0px 4px 14px rgba(201, 198, 198, 0.4) !important; */
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1) !important;
  border-radius: 5px !important;
  background: #fff !important;
  font-size: 16px !important;
  color: #1b95bc !important;
  width: 130px !important;
  border: 0 !important;
  line-height: 100% !important;
  display: inline-block !important;
  padding: 14px !important;
  text-align: center !important;
  font-weight: 600 !important;
  margin: 0 !important;
  cursor: pointer !important;
}
.navbar-toggler-icon{
  background-image: url(../../Assets//Icons/hamburgermenu.svg) !important;
  background-repeat: no-repeat !important;
}

@media (max-width: 991px) {
  .header_inner {
      padding: 19px 8px;
      justify-content: inherit !important;
  }
}
@media  (max-width:576px) {
  .header_inner img{
    width: 120px;
  }
  .header_main .white-btn{
    padding: 10px !important;
  }
}