.prescription_popup .card {
  background: #ddefe1 !important;
}
.pres_width {
  width: 565px;
  position: absolute;
  top: 195px;
  right: 16px;
}
.consulatation_card {
  position: relative;
}
.closeBtn {
  position: absolute;
  right: 10px;
  font-size: 23px;
  top: 0px;
}
.btn-close{
 height: 1px !important;
 width: 1px !important;

}
.common_title {
  font-weight: 700;
  text-align: left;
  margin: 0;
  padding: 19px 31px 12px;
  color: #000;
  font-size: 16px;
}
.upcoming_con_title p {
  padding: 0 31px 6px;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  margin: 0;
}

.upcoming_con_title {
  border-bottom: 1px solid #000;
}

.notes {
  padding: 25px 20px;
  height: 200px;
}
.prescription_info p {
  color: #000 !important;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  padding: 0 11px;

}

.prescription_info li span {
  color: #000 !important;
  font-weight: 500;
  font-size: 14px;
  line-height: 15px;
}
.h-288 {
  height: 221px;
}

.print_btn {
  text-align: center;
  margin: 0 0 32px 0;
}

.print_btn .cre_new {
  border-radius: 5px;
  padding: 5px 16px;
  max-width: 148px;
  width: 100%;
  background-color: var(--sea-green);
  color: white;
}
.bg_color{
margin-top: 15rem !important;
}
@media (min-width:1400px) and (max-width:1600px) {

  .pres_width {
      width: 485px;
  }
}
@media (min-width:1200px) and (max-width:1400px) {

  .pres_width {
      width: 425px;
      top: 170px;
  }
}
@media (min-width:991px) and (max-width:1199px) {

  .pres_width {
      width: 370px;
      top: 170px;
      right: 0;
  }
  .card{
    width: 100% !important;
  }
}
@media (max-width:1199px){
  .pres_width .notes {
    height: 200px;
}
}
@media (max-width:576px) {

  .consulatation_card .table {
      width: 600px;
  }
  .pres_width {
    width: 371px;
}
}
@media (max-width:991px) {

  .pres_width {
    width: 450px;
    position: absolute;
    left: 13px;
    right: unset;
    top: 600px;
}
}
